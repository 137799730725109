// vendors
import styled, { css } from 'styled-components';
import { rem, em } from 'polished';
import colors from '../../styles/colors';
import { greaterThan, lessThan } from '../../utils/mediaQuery';
import { h2Style, withFrontUnderline } from '../../styles/global';
import breakpoints from '../../styles/breakpoints';

export const selfBreakpoint = breakpoints[3];

export const MoreSection = styled.div`
  padding: ${rem(64)} ${rem(28)};

  ${greaterThan(768)} {
    padding: ${rem(112)} ${rem(80)} ${rem(160)};
  }

  background-color: ${colors.white};
`;

export const DetailContainer = styled.div`
  max-width: ${rem(1160)};
  margin: 0 auto ${rem(48)};

  ${greaterThan(selfBreakpoint)} {
    margin-bottom: ${rem(144)};
  }

  ${greaterThan(992)} {
    > div {
      position: relative;

      column-count: 2;
      orphans: 3;
      widows: 3;
      column-gap: ${rem(104)};

      perspective: 1;

      *:first-child {
        margin-top: 0;
      }

      /* > figure {
        break-before: always;
      } */
    }
  }
`;

export const blockStyles = css`
  h2 {
    ${h2Style}

    ${withFrontUnderline}

    break-inside: avoid;
    break-after: avoid;

    /* Pour Firefox : */
    page-break-after: avoid;
    page-break-inside: avoid;

    /* Pour WebKit : */

    /* -webkit-column-break-inside: avoid; */
  }
`;

export const ControlSection = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  max-width: ${rem(1280)};
  margin: auto;

  ${greaterThan(selfBreakpoint)} {
    grid-gap: ${rem(40)};
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CTAWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;

  ${lessThan(selfBreakpoint)} {
    button,
    a {
      min-width: 0;
      padding: ${rem(16)};

      border: 0;
    }

    a span,
    button span {
      margin: 0 !important;
    }
  }

  ${greaterThan(selfBreakpoint)} {
    grid-gap: 40px;
  }
`;

export const AbbrContainer = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${em(52, 20)};
  max-width: ${rem(96)};
  height: ${em(52, 20)};
  max-height: ${rem(96)};
  margin-right: ${em(24, 20)};

  font-size: ${em(20, 20)};

  background-color: currentColor;

  border-radius: 100%;

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${em(40, 36)};
  }
`;

export const AbbrWrapper = styled.span`
  color: ${colors.white};

  text-transform: uppercase;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 0;

  font-weight: 400;
  font-size: 1em;
`;
