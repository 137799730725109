/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useMedia } from 'react-use';
import Layout from '../../components/Layout';
import SheetLayout from '../../components/SheetLayout';
import BlockContent from '../../components/BlockContent';
import Button from '../../components/Button';
import {
  MoreSection,
  DetailContainer,
  blockStyles,
  ControlSection,
  CTAWrapper,
  AbbrContainer,
  AbbrWrapper,
  TitleWrapper,
  Title,
  selfBreakpoint,
} from './ServiceTemplate.styles';
import { biggerStyle } from '../../components/Button/button.styles';
import IconArrow from '../../images/IconArrow';
import {
  lessThanCondition,
  greaterThanCondition,
} from '../../utils/mediaQuery';
import IconCalendar from '../../images/IconCalendar';
import { linkCardColorArray } from '../../components/LinkCardList';
import { useContactForm } from '../../contexts/contactFormContext';
import colors from '../../styles/colors';
import SEO from '../../components/SEO';

const ServiceTemplate = ({ data, pageContext }) => {
  const isMobile = useMedia(lessThanCondition(selfBreakpoint));
  const compactActionButton = useMedia(lessThanCondition(selfBreakpoint));
  const { open } = useContactForm();

  const handleOpenContactForm = () => {
    open();
  };

  const {
    sanityService: service,
    allSanityService: { edges: services },
  } = data;

  const index = services.findIndex(({ node: { id } }) => id === pageContext.id);

  const nextIndex = index + 1 === services.length ? 0 : index + 1;
  const prevIndex = index - 1 < 0 ? services.length - 1 : index - 1;

  const nextURL = `/services/${services[nextIndex].node.slug.current}`;
  const prevURL = `/services/${services[prevIndex].node.slug.current}`;

  const CTASection = (
    <CTAWrapper>
      <Button
        primary
        outlined
        onClick={handleOpenContactForm}
        hasIconOnly={compactActionButton}
        renderIcon={<IconCalendar />}
      >
        Prendre un rendez-vous
      </Button>
    </CTAWrapper>
  );

  const TitleSection = (
    <TitleWrapper>
      <AbbrContainer
        role='presentation'
        style={{ color: linkCardColorArray[index % linkCardColorArray.length] }}
      >
        <AbbrWrapper>{service.abbreviation}</AbbrWrapper>
      </AbbrContainer>

      <Title>{service.name}</Title>
    </TitleWrapper>
  );

  return (
    <Layout backgroundColor={colors.veryLightPink}>
      <SEO
        title={service.seo.seo_title}
        description={service.seo.meta_description}
      />

      <article>
        <SheetLayout
          renderHeader={TitleSection}
          title={service.name}
          actionSection={CTASection}
        >
          <BlockContent
            blocks={service._rawBody}
            css={blockStyles}
            imageOptions={{
              maxWidth: 960,
              sizes: `${greaterThanCondition(768)} ${
                (832 / 992) * 100
              }vw, ${greaterThanCondition(992)} ${
                (960 / 1200) * 100
              }vw, ${greaterThanCondition(1200)} 960px, ${(721 / 768) * 100}vw`,
            }}
          />
        </SheetLayout>

        <MoreSection>
          {service._rawDetail && (
            <DetailContainer>
              <BlockContent
                blocks={service._rawDetail}
                css={blockStyles}
                imageOptions={{
                  maxWidth: 832,
                  sizes: `${greaterThanCondition(768)} ${
                    (832 / 992) * 100
                  }vw, ${greaterThanCondition(992)} ${
                    (528 / 1320) * 100
                  }vw, ${greaterThanCondition(1320)} 528px, ${
                    (712 / 768) * 100
                  }vw`,
                }}
              />
            </DetailContainer>
          )}

          <ControlSection>
            <Button
              outlined
              css={biggerStyle}
              renderIcon={<IconArrow style={{ transform: 'rotate(180deg)' }} />}
              iconFirst
              hasIconOnly={isMobile}
              to={prevURL}
            >
              Service précédent
            </Button>

            <Button outlined primary to='/services' css={biggerStyle}>
              Tous les services
            </Button>

            <Button
              outlined
              css={biggerStyle}
              renderIcon={<IconArrow />}
              hasIconOnly={isMobile}
              to={nextURL}
            >
              Service suivant
            </Button>
          </ControlSection>
        </MoreSection>
      </article>
    </Layout>
  );
};

ServiceTemplate.propTypes = {
  data: PropTypes.shape({
    sanityService: PropTypes.shape({}).isRequired,
    allSanityService: PropTypes.shape({
      edges: PropTypes.arrayOf().isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ServiceTemplate;

export const query = graphql`
  query($id: String!) {
    allSanityService(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          id
          slug {
            current
          }
        }
      }
    }
    sanityService(id: { eq: $id }) {
      name
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawDetail(resolveReferences: { maxDepth: 10 })
      abbreviation
      seo {
        meta_description
        seo_title
      }
      slug {
        current
      }
    }
  }
`;
